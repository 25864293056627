import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`It was a hot summer in Phoenix, Arizona. The sun was blazing above the millions of citizens living there. For some people, it would just be an ordinary day, but for 16-year-old Ingvar, things were about to take a steep turn. Ever since they moved to Phoenix from Stockholm, everything has been going great for them. His parents became world-renowned microbiologists, and that gave Ingvar the chance to go to a private high school, Phoenix high. His lifelong dream to become a professional NFL player is in his advantage. Scouts were starting to visit and watch him play, and today, the Arizona Cardinals scouts were about to watch him play. His parents dropped him off at school, as usual. He left the car in his athletic look thinking this was going to be the big day. Ingvar knew his parents were busy workers, they would both travel for weeks at a time all around the world, but what was about to happen is something no 16-year old boy can prepare for.`}</p>
    <p>{`During his game, Ingvar scored the game-winning touchdown. The whole crowd was roaring, fireworks were sparking, the field was rumbling, everything was going great. Ingvar felt the real power inside of him. He decided to take the school bus home with the team, they were singing songs, even the bus danced along. When he got dropped off at his house, the whole squad congratulated him, little did they know it was going to be the last time...`}</p>
    <p>{`He got home to the letter on the kitchen counter, the house felt cold and alone, like life had disappeared from it. Ingvar got flooded with nervousness. His face became numb as he slowly made his way around the house. Ingvar returned back to the kitchen and picked up the letter, it read;`}</p>
    <p><em parentName="p">{`Dear Ingvarchik`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`We are sorry to leave on such short notice, and without informing you but this was an urgent matter. A new deadly virus had just been discovered in La Paz, Bolivia, 320,000 people have already died, and 40,000 more are in hospitals. The entire city has been quarantined, and no one is sure of the virus’s origins. Evo Morales, the president of Bolivia personally contacted your father and me to help them, before we could make a decision there was a car waiting for uts outside, we knew this is what we came here to do, and this is our chance. We could not leave you any money, but we’ll only be gone for a couple of weeks if everything goes according to plan. Really sorry for this but we would be putting at stake possibly millions of lives, something we physically could not do. We hope you can forgive us.`}<br parentName="em"></br>
        {`Love, mom.`}</em></p>
    <p>{`Ingvar didn’t know what to do, he was scared, he was nervous, he was lost. He had no relatives in the US, he had no way to contact his parents. He had to sort something out quickly. The money he saved up from his middle school lemonade stands and garage sales would soon run out. He knew he could no longer afford the school, the check for the second semester was sent 2 days prior, and there was nothing he could do. The football state finals were coming up, he knew his team wouldn’t manage without him, and he knew that would’ve been his big chance to shine for colleges. Ingvar quickly took his bike from out of the garage and rode to his best friend’s house. His bike whistled through the sunny streets of Phoenix like never before, he needed some support with this, and he needed it fast.`}</p>
    <p>{`When he and his family first moved to Arizona, he was an outcast, he got bullied and picked on. On the 3rd month, he met a kid, you could call him the popular kid, or maybe the class clown, but he knew Ingvar needed help. One afternoon after class, Jerome came up to Ingvar and asked to be his friend, ever since then they have been inseparable.`}</p>
    <p>{`On this day, he really needed help, and he couldn’t trust anyone except Jerome. Sure, he made new friends in his football team, but he knew that in these moments they won’t be there for him. When he got to Jerome’s house, he rang the doorbell until his hand got tired, and he switched to the next one. His heart was beating at supersonic speeds. When Jerome finally opened the door, Ingvar was redder than a tomato. He quickly started spitting out words, he told Jerome everything, the letter, the virus, his parents, and how he desperately needed help. Jerome let him into his house, asked his mom to make them some food and went upstairs. Ingvar was shaking with anxiety, he was scared for his parents and himself. He knew he wouldn’t be able to afford the second semester of school, and he would have to quit. Jerome offered to give him some money, but it wouldn’t be nearly enough. Jerome told him he needed to find a job if they didn’t return during the next couple of months. Ingvar couldn’t grasp this idea of what was happening, and just how fast everything changes, he broke into tears.`}</p>
    <p>{`When he returned home, he tried to calm himself down. He knew panic wasn’t the way to go, and it would only make things harder. The next day he came to school but didn’t tell anyone about his situation. Ingvar knew if authorities found out, he wouldn’t be allowed to live in his house anymore, and he couldn’t bear to lose that too. He only had 2 weeks of school left until the payment was due, but he had to make the most of it.`}</p>
    <p>{`On the last week of school, he was beginning to feel much better. He tried to completely forget about his parents, hiding all the photos, the paintings, and the memories, the countless amounts of memories he had of them. One day he got home and couldn’t take it anymore. He broke out into a tantrum, tears were pouring out like waterfalls, he was destroying everything he saw, the plates, the doors, the windows. He was letting all that anger out through his fists, the entire street could feel his wrath.`}</p>
    <p>{`Jerome noticed how severe things were getting. The next day Ingvar came to school with bleeding knuckles and the facial expression of a serial killer. After talking with his mom, Jerome got the money to buy Ingvar a plane ticket to La Paz. It wasn’t a cheap gift, and his family wasn’t too fortunate, but Jerome was taught to always put others in front of him. His parents were also strongly religious, and they had to set a good example for their kid. Jerome brought the tickets to school the next day. He was excited to see his best friend's reaction, but to his surprise, Ingvar wasn’t there. Having spent 7 years with Ingvar, he knew to fear the worst.`}</p>
    <p>{`He sprinted all the way to Ingvar’s house, bursting through the unlocked front door. He started shouting Ingvar’s name while power-walking around the seemingly empty, eerie house. He was about to leave when he heard a slight whimper coming from above. It was coming from the attic. Jerome quickly made his way around the house and got up to the attic. As he got up, he saw a silhouette of a boy in the corner of a dark, empty room, Ingvar was in tears. He was going through photos of his parents and crying after each one, it was like he couldn’t stop. He had been up there for hours, reliving memories. Jerome almost broke a tear but stayed strong, he knew he had to help a friend. Jerome helped Ingvar make his way downstairs, and out to the porch to get some fresh air, to get all that dampness away.`}</p>
    <p>{`After letting Ingvar rest for a couple of minutes he presented him with the tickets, Ingvar froze. He froze instantly, his jaw dropped below his chin, and his eyes were as wide as ping-pong balls. Jerome could feel Ingvar’s excitement, his joy, and it made him just oh so happy. Ingvar didn’t know how to respond, he started squealing from joy, not able to express his emotions in words. He checked the date on the tickets, ‘this Thursday’, He only had three days to get ready.`}</p>
    <p>{`With no plan on finding his parents, it was finally Thursday, the big day. Jerome’s parents were willing to drive Ingvar to the airport, and get him through security, since he still wasn’t of legal age. As he got on the plane, adrenaline was pumping through his blood faster than a bolt of lightning, he was shivering from all these inexplicable emotions running around him. It was an 11-hour flight, 11 hours of pure blasts of emotions. Shortly after take-off, the plane started experiencing abnormal turbulence. Ingvar managed to fall asleep until suddenly…`}</p>
    <p>{`“Hello everyone this is your captain speaking, We are currently descending at rapid speeds due to an unknown issue with the turbine engines, The oxygen masks will drop from above and seats shortly, and be prepared to brace for impact.”`}</p>
    <p>{`Screams started exploding in the plane. Everyone was panicking, except for Ingvar. He accepted this as the end, he knew he wouldn’t have succeeded in Bolivia without a plan, but all the emotions clouded his common sense.`}</p>
    <p>{`He calmly got into the brace position as he was approaching the light, he slowly took his last breath and...`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      